import React from "react";
import {
  MenuBox,
  MenuItem,
  SubMenuBox,
  DropdownBox,
  SubMenuItem
} from "./NavMenu.style";

const NavMenu = ({ menuItems }) => {
  return (
    <MenuBox direction="row">
      {menuItems.map(item => {
        if (item.subItems != null && item.subItems.length !== 0) {
          return (
            <SubMenuBox align="center" key={item.item}>
              <MenuItem label={item.item} href={item.ref} />
              <DropdownBox align="center" pad="small">
                {item.subItems.map(subItem => {
                  return (
                    <SubMenuItem
                      label={subItem.item}
                      href={subItem.ref}
                      key={subItem.item}
                    />
                  );
                })}
              </DropdownBox>
            </SubMenuBox>
          );
        } else {
          return <MenuItem label={item.item} href={item.ref} key={item.item} />;
        }
      })}
    </MenuBox>
  );
};

export default NavMenu;
