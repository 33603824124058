import Colors from "./colors.js";

const theme = {
  global: {
    colors: {
      ...Colors
    },
    containerSize: "114rem",
    breakpoints: {
      small: {
        value: 1023
      },
      medium: {
        value: 1200
      },
      large: {
        value: 3000
      }
    },
    input: {
      weight: 400,
      family: "Montserrat"
    }
  },
  button: {
    color: {
      dark: Colors.white,
      light: Colors.primary
    },
    primary: {
      color: {
        light: Colors.primary
      }
    },
    border: {
      width: "0px"
    },
    extend: {
      fontWeight: 600
    }
  },
  anchor: {
    fontWeight: 500,
    color: {
      // dark: "#fff",
      light: "#081930"
      // light: "#fff"
    }
  },
  text: {
    color: Colors.text,
    medium: {
      size: "1.6rem"
    }
  },
  accordion: {
    icons: {
      color: Colors.secondary
    }
  }
};

export default theme;
