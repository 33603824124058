import React, { Component } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { ContainerBox, HeaderBox, FooterBox, ContentBox } from "./Layout.style";

export class Layout extends Component {
  render() {
    return (
      <ContainerBox>
        <HeaderBox
          justify="center"
          align="center"
          border={{
            side: "bottom",
            color: "#eee",
            size: "xsmall"
          }}
        >
          <Header />
        </HeaderBox>
        {/* <ContentBoxWrapper> */}
        <ContentBox justify="start" align="stretch">
          {this.props.children}
        </ContentBox>
        <FooterBox background="footer" align="center" justify="center">
          <Footer />
        </FooterBox>
        {/* </ContentBoxWrapper> */}
      </ContainerBox>
    );
  }
}

export default Layout;
