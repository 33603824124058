import React from "react";
import { Box, Text } from "grommet";
import styled from "styled-components";

const SearchedResultItem = ({
  brand,
  type,
  model,
  cs,
  sw,
  csLimitedCharging
}) => {
  return (
    <Container>
      <Box background="greyLight02" pad="xsmall">
        <Text weight="bold">{`${brand ? brand : ""} ${
          type ? type : ""
        } ${model.join(" / ")}`}</Text>
      </Box>

      <CompatibleBox
        direction="row"
        gap="medium"
        background="white"
        pad="xsmall"
        wrap={true}
        border={{
          color: "border",
          size: "xsmall",
          style: "solid",
          side: "all"
        }}
      >
        <Text>
          Compatible with Charby Sense: <strong>{cs}</strong>
          {csLimitedCharging && " (Standard Speed)"}
        </Text>
        <Text>
          Compatible with Charby Swift: <strong>{sw ? "Yes" : "No"}</strong>
        </Text>
      </CompatibleBox>
    </Container>
  );
};

const Container = styled(Box)``;

const CompatibleBox = styled(Box)``;

export default SearchedResultItem;
