import React from "react";
import { LogoHome as Logo, LogoBox, LogoWhite } from "./Logo.style";
import { Anchor } from "grommet";

const LogoHome = ({ white }) => {
  return (
    <Anchor href="https://charbycharge.com/">
      <LogoBox basis="full">{white ? <LogoWhite /> : <Logo />}</LogoBox>
    </Anchor>
  );
};

export default LogoHome;
