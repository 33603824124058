import styled from "styled-components";
import Logo from "../../assets/images/logo.png";
import icLogoWhite from "../../assets/images/ic_logo_white.png";
import { Box } from "grommet";

export const LogoHome = styled.img.attrs({
  src: Logo,
  alt: "logo"
})`
  height: 3rem;
  width: auto;
  /* margin-left: 3.4rem; */
`;

export const LogoWhite = styled.img.attrs({
  src: icLogoWhite,
  alt: "logo"
})`
  height: 3rem;
  width: auto;
`;

export const LogoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
