import React, { Component } from "react";
import { Text } from "grommet";

export class Footer extends Component {
  render() {
    return (
      <Text size="1.2rem" textAlign="center" pad="5rem">
        Created & Powered By Charby Sdn Bhd. All Copyrights Reserved © 2019
      </Text>
    );
  }
}

export default Footer;
