import React from "react";
import {
  VerticalMenuBox,
  MenuItem,
  SubMenuBox,
  SubMenuItem
} from "./NavMenu.style";
import { Close } from "grommet-icons";
import { Box } from "grommet";
import LogoHome from "../Logo/LogoHome";

const VerticalNavMenu = ({ menuItems, toggleMenuOpen }) => {
  return (
    <VerticalMenuBox direction="column" pad={{ horizontal: "3rem" }}>
      <Box margin={{ vertical: "4rem" }} justify="between" direction="row">
        <Box width="3rem" height="1rem" />
        <Box width="3rem">
          <LogoHome white />
        </Box>
        <Box width="3rem" height="1rem">
          <Close color="white" onClick={toggleMenuOpen} />
        </Box>
      </Box>
      <Box gap="large" margin={{ top: "3rem" }}>
        {menuItems.map(item => {
          if (item.subItems != null && item.subItems.length !== 0) {
            return (
              <SubMenuBox align="center" key={item.item}>
                <MenuItem label={item.item} href={item.ref} color="white" />
                <Box margin={{ left: "3rem", top: "1rem" }}>
                  {item.subItems.map(subItem => {
                    return (
                      <SubMenuItem
                        label={`- ${subItem.item}`}
                        href={subItem.ref}
                        color="white"
                        key={subItem.item}
                      />
                    );
                  })}
                </Box>
              </SubMenuBox>
            );
          } else {
            return (
              <MenuItem
                label={item.item}
                href={item.ref}
                color="white"
                key={item.item}
              />
            );
          }
        })}
      </Box>
    </VerticalMenuBox>
  );
};

export default VerticalNavMenu;
