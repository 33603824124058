export default {
  primary: "#001E45",
  primary02: "#005992",

  secondary: "#ffa600",

  error: "#cc0000",

  greyLight01: "#BCBEC0",
  greyLight02: "#E6E7E8",
  border: "#E6E7E8",
  greyDark01: "#333132",
  greyDark02: "#4d4d4f",

  text: "#2c2b29",

  white: "#fff",
  black: "#000",
  black02: "#121212",

  footer: "#1F232C"
};
