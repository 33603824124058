import styled from "styled-components";
import { Box, Anchor } from "grommet";

export const MenuBox = styled(Box)`
  margin-left: 3.3rem;
`;

export const DropdownBox = styled(Box)`
  min-width: 17rem;
  display: none;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  align-items: center;
  padding-top: 2.5rem;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.3);
`;

export const SubMenuBox = styled(Box)`
  position: relative;
  display: block;
  &:hover ${DropdownBox} {
    display: flex;
  }
`;

export const MenuItem = styled(Anchor)`
  text-transform: uppercase;
  font-size: 1.4rem;
  &:not(:last-child) {
    margin-right: 4rem;
  }
`;

export const SubMenuItem = styled(Anchor)`
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 1rem 0;
  &:hover,
  &:active {
    text-decoration: none;
    color: ${props => props.theme.global.colors.primary02};
  }
`;

export const VerticalMenuBox = styled(Box)`
  background-color: #00304f;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;
