import React, { Component } from "react";
import { SectionBox, ColumnBox } from "./Header.style";
import Logo from "../../components/Logo/LogoHome";
import { Menu } from "grommet-icons";
import NavMenu from "../../components/NavMenu/NavMenu";
import VerticalNavMenu from "../../components/NavMenu/VerticalNavMenu";
import { ResponsiveContext } from "grommet";

const MenuItems = [
  {
    item: "Cable",
    subItems: [
      {
        item: "Charby Sense",
        ref:
          "https://charbycharge.com/charby-sense-worlds-smartest-auto-cutoff-cable/"
      }
    ],
    ref: ""
  },
  {
    item: "Power Bank",
    subItems: [
      {
        item: "Charby Swift",
        ref:
          "https://charbycharge.com/charby-swift-10000-power-delivery-portable-charger/"
      }
    ],
    ref: ""
  },
  {
    item: "Shop",
    subItems: [
      {
        item: "Charby Sense - Lightning Cable",
        ref: "https://charbycharge.com/shop/lightning-to-usb-cable/"
      },
      {
        item: "Charby Sense - Micro USB Cable",
        ref: "https://charbycharge.com/shop/micro-usb-to-usb-cable/"
      },
      {
        item: "Charby Sense - USB C Cable",
        ref: "https://charbycharge.com/shop/usb-c-to-usb-a-cable/"
      },
      {
        item: "Charby Swift - Power Bank",
        ref: "https://charbycharge.com/shop/usb-type-c-power-bank/"
      }
    ],
    ref: ""
  },
  {
    item: "Contact Us",
    subItems: null,
    ref: "https://charbycharge.com/contact-us/"
  },
  { item: "Blog", subItems: null, ref: "https://charbycharge.com/blog/" }
];
class Header extends Component {
  state = {
    menuOpen: false
  };

  toggleMenuOpen = () => {
    const menuState = this.state.menuOpen;
    this.setState({
      menuOpen: !menuState
    });
  };
  render() {
    const { menuOpen } = this.state;
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <SectionBox
            direction="row"
            justify={size === "small" ? "between" : "start"}
          >
            {size === "small" && (
              <ColumnBox justify="center" align="center" width="8rem">
                {/* <NavMenu menuItems={MenuItems} /> */}
                <Menu onClick={this.toggleMenuOpen} />
                {menuOpen && (
                  <VerticalNavMenu
                    menuItems={MenuItems}
                    toggleMenuOpen={this.toggleMenuOpen}
                  />
                )}
              </ColumnBox>
            )}
            <ColumnBox width="8rem">
              <Logo />
            </ColumnBox>
            {size !== "small" && (
              <ColumnBox justify="center" align="center">
                <NavMenu menuItems={MenuItems} />
              </ColumnBox>
            )}
            <ColumnBox width="8rem" />
          </SectionBox>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

export default Header;
