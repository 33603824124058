import styled from "styled-components";
import { Box } from "grommet";

export const ContainerBox = styled(Box)`
  min-height: 100%;

  /* position: relative; */
`;

export const HeaderBox = styled(Box)`
  flex: 0 0 7rem;
`;
// export const ContentBoxWrapper = styled(Box)`
//   flex: 1;
//   /* overflow-x: clip;
//   overflow-y: auto; */
// `;
export const ContentBox = styled(Box)`
  flex: 1;
`;
export const FooterBox = styled(Box)`
  flex: 0 0 5rem;
  /* position: absolute;
  bottom: 0; */
  /* overflow-y: auto; */
`;
