import React, { Component } from "react";
import styled from "styled-components";
import { Box, TextInput, Heading, ResponsiveContext, Text } from "grommet";
import PublicIP from "public-ip";
// import TextInput from "../../components/FieldInputs/TextInput";
// import { appleList } from "../../assets/data/apple";
import Fuse from "fuse.js";
import ResultItem from "../../components/SearchedResultItem/SearchedResultItem";

export class SearchDashboard extends Component {
  state = {
    compatibleList: [],
    inputValue: "",
    inputJustCleared: true,
    searchedResult: [],
    locationData: {},
    date: ""
  };

  componentDidMount = async () => {
    const data = await this.fetchApiAndChangeFormat(
      // "https://api.sheety.co/6bdcee25-8086-4cff-9c19-7662981ec27d"
      "https://api.steinhq.com/v1/storages/5d2e9192490adc53ef5c2c9c/PhoneCompatibilityList"
    );
    this.setState({
      compatibleList: data
    });
    const locationData = await this.fetchIpCheckLocation();
    const date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 10);
    this.setState({
      locationData: locationData,
      date: date
    });
    // console.log(
    //   "date",
    //   new Date(
    //     Date.now() - new Date().getTimezoneOffset() * 60000
    //   ).toISOString()
    // );
    // console.log("api data", this.state.compatibleList);
  };

  fetchIpCheckLocation = async () => {
    const ip = await PublicIP.v4();
    // console.log("ip", ip);
    // const accessKey = "480ea4dd3a800a2701f3d94b52e5818f";
    // const api = `http://api.ipstack.com/${ip}?access_key=${accessKey}`;
    const api = `https://ipapi.co/${ip}/json/`;
    const response = await fetch(api);
    const location = await response.json();
    // console.log("location", location);
    return location;
  };

  fetchApiAndChangeFormat = async api => {
    const response = await fetch(api);
    const data = await response.json();
    // console.log("data", data);
    let newData = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].model !== null) {
        if (typeof data[i].model !== "string") {
          data[i].model = data[i].model.toString();
        }
        data[i].model = data[i].model.split(" / ");
        newData.push(Object.assign({}, data[i]));
      }
    }
    return newData;
  };

  onInputChanged = event => {
    this.setState({ inputValue: event.target.value });
    if (event.target.value === "") {
      this.setState({ searchedResult: [], inputJustCleared: true });
    }
  };

  sendSearchQuery = async data => {
    const response = await fetch(
      "https://api.steinhq.com/v1/storages/5d2e9192490adc53ef5c2c9c/Search",
      {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }
    );
    return await response.json();
  };

  searchSubmit = () => {
    if (this.state.inputValue !== "") {
      var options = {
        shouldSort: true,
        tokenize: true,
        findAllMatches: true,
        threshold: 0.0,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 2,
        keys: [
          { name: "brand", weight: 0.2 },
          { name: "type", weight: 0.5 },
          { name: "model", weight: 0.3 }
        ]
      };
      var fuse = new Fuse(this.state.compatibleList, options);
      const result = fuse.search(this.state.inputValue);
      let searchData = {};
      if (result.length > 0) {
        this.setState({ searchedResult: result, inputJustCleared: false });
      } else {
        this.setState({ searchedResult: [], inputJustCleared: false });
        searchData = {
          no_result: true
        };
      }
      this.sendSearchQuery([
        {
          ...searchData,
          search: this.state.inputValue,
          ...this.state.locationData,
          date: this.state.date
        }
      ]);
      // console.log("fuse", result);
    } else {
      // console.log("Empty String");
    }
  };

  handleKeyPress = target => {
    if (target.charCode === 13) {
      this.searchSubmit();
    }
  };

  render() {
    let results = null;
    let endingText = `Your device isn't listed. Please contact
    support@charbycharge.com and we'll look it up for you.`;
    const { searchedResult, inputJustCleared } = this.state;
    if (searchedResult.length > 0) {
      results = searchedResult.map((result, index) => {
        return <ResultItem key={index} {...result} />;
      });
      endingText = `If your device isn't listed. Please contact
    support@charbycharge.com and we'll look it up for you.`;
    }
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Container alignSelf="center" justify="start" align="center">
            <InputContainer
              margin={{
                top: size === "small" ? "5rem" : "20rem",
                bottom: "5rem"
              }}
            >
              <Box align="center">
                <Heading
                  level={1}
                  size="small"
                  color="primary02"
                  textAlign="center"
                >
                  Charby Products Compatibility Check
                </Heading>
              </Box>
              <Box direction="row" gap="xsmall" margin={{ top: "5rem" }}>
                <TextInput
                  placeholder="Phone Model e.g. iPhone XS"
                  value={this.state.inputValue}
                  onChange={this.onInputChanged}
                  onKeyPress={this.handleKeyPress}
                />
                <SearchButton onClick={this.searchSubmit}>Check</SearchButton>
              </Box>
              <Box margin={{ top: "2rem" }}>{results}</Box>
              {!inputJustCleared ? (
                <Box margin={{ top: "1rem" }}>
                  <Text weight="bold" size="small" textAlign="center">
                    {endingText}
                  </Text>
                </Box>
              ) : null}
            </InputContainer>
          </Container>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const Container = styled(Box)`
  flex: 1;
  width: 110rem;
`;
const InputContainer = styled(Box)`
  width: 90%;
  max-width: 85rem;
  border-radius: 0px;
`;

const SearchButton = styled.button`
  padding: 0.5rem 2.5rem;
  border: none;
  background-color: ${props => props.theme.global.colors.primary02};
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0.5rem;
  &:hover {
    background-color: ${props => props.theme.global.colors.black02};
  }
`;
export default SearchDashboard;
